import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { Link, useIntl } from '../../../plugins/gatsby-plugin-intl-kley'
import useLinkLocalized from '../../hooks/useLinkLocalized'

export default function Sitemap({pagesIntlData, residencesData}) {
  // const {
  //   allPageIntl: { edges: pagesIntl },
  //   allUmbracoResidencePage: { nodes: residences },
  // } = useStaticQuery(graphql`
  //   query SinglePageSitemapQuery {
  //     allPageIntl(filter: { lang: { eq: "fr" } }) {
  //       edges {
  //         node {
  //           name
  //           slug
  //           pageIdentifier
  //           lang
  //           uri
  //           pageIntlId
  //           pageIntl
  //           alternates {
  //             isDefault
  //             name
  //             uri
  //             lang
  //           }
  //         }
  //       }
  //     }
  //     allUmbracoResidencePage(filter: { lang: { eq: "fr" } }) {
  //       nodes {
  //         id
  //         name
  //         _url
  //         _urls {
  //           fr
  //           en_us
  //         }
  //         shortName
  //         shortDescription
  //         title
  //         comingSoon
  //       }
  //     }
  //   }
  // `)
  const pagesIntl = pagesIntlData
  const residences = residencesData
  const { locale } = useIntl()

  useEffect(() => {
    console.log("pagesIntl", pagesIntl)
  }, [])

  return (
    <section>
      <div className="container">
        <div className="md:grid md:grid-cols-6 gap-4">
          {pagesIntl &&
            pagesIntl.map((page: any, index: Number) => {
              const residence = residences.find(
                (residenceItem: any) => residenceItem._url === page.node.uri
              )

              if (residence && residence.comingSoon === true) {
                return undefined
              }

              return (
                page.node && page.node.lang === locale && 
                (
                  <Link
                    key={`sitemapi${index}`}
                    to={useLinkLocalized({ url: page.node.uri })}
                    className={`underline text-vert-fonce hover:no-underline hover:text-vert-claire`}
                  >
                    {page.node.name}
                  </Link>
                )
              )
            })}
        </div>
      </div>
    </section>
  )
}
